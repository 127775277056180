import { makeStyles } from "@material-ui/styles";

export const drawerWidth = 300;
export default makeStyles((theme) => ({
  tableHead: {
    color:'orange',
    '.MuiTableCell-head':{
        color:'orange',
    }
  },
}));
